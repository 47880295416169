import { ThemeDefault } from '@seddi/ui-kit';

export const decoratorPrimary = 'rgb(50, 147, 111)';
export const decoratorSecondary = 'rgb(103, 203, 166)';

const color = {
  ...ThemeDefault.color,
  sa1: 'rgba(78, 15, 210, 1)',
  sa2: 'rgba(113, 66, 255, 1)',
  sd1: 'rgba(50, 147, 111, 1)',
  sd2: 'rgba(103, 203, 166, 1)',
  st1: 'rgba(0, 179, 179, 1)',
  st2: 'rgba(0, 203, 203, 1)',
  sf1: 'rgba(217, 3, 104, 1)',
  sf2: 'rgba(255, 52, 148, 1)',
  ac21: 'rgba(255,145,0,1)',
  ac22: 'rgba(255,179,64,1)',
};

export const fitRoomGradient = `
    radial-gradient(47.69% 118.91% at 97.69% 96.89%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(23.69% 37.41% at 99.06% 0%, rgba(254, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(112.07deg, rgba(254, 241, 225, 0.8) -0.19%, rgba(227, 48, 112, 0.8) 99.7%),
    #FFFFFF
`;

export const authorGradient = `
    radial-gradient(47.69% 118.91% at 97.69% 96.89%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
    radial-gradient(23.69% 37.41% at 99.06% 0%, rgba(254, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%),
    linear-gradient(112.07deg, rgba(225, 247, 254, 0.8) -0.19%, rgba(47, 0, 181, 0.8) 99.7%),
    #FFFFFF
`;

export const texturaGradient = `
    radial-gradient(118.95% 47.8% at 97.69% 96.89%, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), 
    radial-gradient(44.28% 44.28% at 99.06% 0%, rgba(254, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%), 
    linear-gradient(112deg, rgba(225, 254, 251, 0.80) -0.19%, rgba(234, 246, 244, 0.80) -0.18%, rgba(34, 134, 134, 0.80) 99.7%), 
    #FFF
`;

export const seddiGradient = `
radial-gradient(47.69% 118.91% at 97.69% 96.89%,rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
radial-gradient(23.69% 37.41% at 99.06% 0%, rgba(254, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%),
linear-gradient(112.07deg, #e5eeef -0.19%, #ddd 99.7%), #FFFFFF`;

export const decoratorGradient = '#e8e8e8';

export const ThemeApp = {
  ...ThemeDefault,
  color,
};
